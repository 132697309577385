import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, functions } from '../config/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { CountryDropdown } from 'react-country-region-selector';
import { TailSpin } from 'react-loader-spinner';
import '../style/MasterAccountSetup.css';
import logo2 from '../assets/logo-wordless.png';
import TermsOfServiceModal from '../components/ToSmodal'; // Import the modal component

const stripePromise = loadStripe('pk_live_51NEDchBQRI88GTVxMlp7NefC69fO61iMpBAbJnmRld7kRMzuXqVrRdaoo0ngAwgHGFRoeHBiYFEZKt7YBJA0jdwW00CUghgQOr');

const MasterAccountSetup = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [clinicId, setClinicId] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [country, setCountry] = useState('United States');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [numberOfDoctors, setNumberOfDoctors] = useState(1);
  const [paymentPeriod, setPaymentPeriod] = useState('monthly');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isTermsAccepted, setIsTermsAccepted] = useState(false); // New state for terms acceptance
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);

        // Fetch the clinic ID associated with the user
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const clinicId = userDoc.data().clinicId;
          setClinicId(clinicId);

          // Fetch the Stripe customer ID for the clinic
          const clinicDoc = await getDoc(doc(db, 'clinics', clinicId));
          if (clinicDoc.exists()) {
            setStripeCustomerId(clinicDoc.data().stripeCustomerId);
          }
        }
      } else {
        alert("You must be logged in to set up a master account.");
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const validateFields = () => {
    const newErrors = {};
    if (!country) newErrors.country = 'Country is required';
    if (country === 'United States' && !zipCode) newErrors.zipCode = 'Zip code is required';
    if (country !== 'United States' && !city) newErrors.city = 'City is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validatePromoCode = async () => {
    try {
      if (paymentPeriod === 'annual') {
        alert("Promo not valid on annual plans");
        setIsPromoValid(false);
        return;
      }

      const promoDoc = await getDoc(doc(db, 'PromoCodes', promoCode));
      if (promoDoc.exists() && !promoDoc.data().used) {
        setIsPromoValid(true);
      } else {
        setIsPromoValid(false);
        alert("Invalid promo code.");
      }
    } catch (error) {
      console.error("Error validating promo code:", error);
      alert("An error occurred while validating the promo code. Please try again later.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isTermsAccepted) {
      alert("Please accept the Terms of Service before proceeding to checkout.");
      return;
    }

    // Proceed with the rest of the submission logic
    if (!currentUser || !clinicId || !stripeCustomerId) {
      alert("Missing required information. Please contact support.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    setLoading(true);

    try {
      // Update clinic information in Firestore
      const clinicDocRef = doc(db, 'clinics', clinicId);
      await updateDoc(clinicDocRef, {
        country,
        city: country !== 'United States' ? city : '',
        zipCode: country === 'United States' ? zipCode : '',
        numberOfDoctors,
        paymentPeriod,
        subscriptionStatus: 'inactive',
      });

      // Set the user's profile to complete
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        isProfileComplete: true,
      });

      // Create Stripe Checkout session based on selected plan
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const { data: { sessionId } } = await createCheckoutSession({
        numberOfDoctors,
        paymentPeriod,
        isPromoValid, // Include this in the data sent to the function
      });

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Error during Stripe Checkout:", error);
        alert("An error occurred during payment. Please try again later.");
      }
    } catch (error) {
      console.error("Error during master account setup:", error);
      alert("An error occurred during master account setup. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getPricing = () => {
    if (isPromoValid) {
      return "Free 3 Months!";
    }
    const monthlyPrices = {
      '1': 199, // Price for 1-2 doctors
      '3': 399, // Price for 3-5 doctors
      '6': 599  // Price for 6-8 doctors
    };
    const annualPrices = {
      '1': 2200, // Annual price for 1-2 doctors
      '3': 4500, // Annual price for 3-5 doctors
      '6': 6800  // Annual price for 6-8 doctors
    };

    let price;
    if (numberOfDoctors >= 1 && numberOfDoctors <= 2) {
      price = paymentPeriod === 'monthly' ? monthlyPrices['1'] : annualPrices['1'];
    } else if (numberOfDoctors >= 3 && numberOfDoctors <= 5) {
      price = paymentPeriod === 'monthly' ? monthlyPrices['3'] : annualPrices['3'];
    } else if (numberOfDoctors >= 6 && numberOfDoctors <= 8) {
      price = paymentPeriod === 'monthly' ? monthlyPrices['6'] : annualPrices['6'];
    } else if (numberOfDoctors > 8) {
      price = "Contact us for enterprise pricing"; // For more than 8 doctors
    } else {
      price = "Invalid number of doctors"; // For edge cases or errors
    }

    return typeof price === 'number' ? `$${price} USD ${paymentPeriod === 'monthly' ? 'per month' : 'per year'}` : price;
  };

  const handleCheckboxClick = () => {
    setIsModalOpen(true);
  };

  const handleModalAccept = () => {
    setIsTermsAccepted(true);
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="setup-container">
      <div className="setup-logo-container">
        <img src={logo2} alt="Logo" className="setup-logo" />
      </div>
      <div className="setup-content">
        <h2 className="setup-title">Master Account Setup</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Country</label>
            <CountryDropdown
              value={country}
              onChange={(val) => setCountry(val)}
              priorityOptions={['US', 'CA', 'GB']}
              defaultOptionLabel="Select your country"
              required
              className="dropdown"
            />
            {errors.country && <div className="error-message">{errors.country}</div>}
          </div>
          {country === 'United States' && (
            <div className="input-group">
              <label>Zip Code</label>
              <input
                type="text"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                required
                placeholder="Enter zip code"
              />
              {errors.zipCode && <div className="error-message">{errors.zipCode}</div>}
            </div>
          )}
          {country !== 'United States' && (
            <div className="input-group">
              <label>City</label>
              <input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
                placeholder="Enter city"
              />
              {errors.city && <div className="error-message">{errors.city}</div>}
            </div>
          )}
          <div className="input-group-1">
            <label>Number of Doctors</label>
            <div className="slider-value">
              {numberOfDoctors <= 8 ? numberOfDoctors : '9+'}
            </div>
            <input
              type="range"
              min="1"
              max="9"
              value={numberOfDoctors}
              onChange={(e) => setNumberOfDoctors(parseInt(e.target.value))}
              className="slider"
            />
            <div className="pricing">{'Unlimited Team Use'}</div>
          </div>
          <div className="input-group">
            <label>Payment Period</label>
            <div className="payment-period-toggle">
              <div className={`toggle-option ${paymentPeriod === 'monthly' ? 'active' : ''}`} onClick={() => setPaymentPeriod('monthly')}>
                Monthly
              </div>
              <div className={`toggle-option ${paymentPeriod === 'annual' ? 'active' : ''}`} onClick={() => setPaymentPeriod('annual')}>
                Annual
              </div>
            </div>
          </div>
          <div className="input-group">
            <label>Promo Code</label>
            <input
              type="text"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="Enter promo code"
            />
            <button type="button" onClick={validatePromoCode} disabled={loading}>
              Apply Promo Code
            </button>
          </div>
          <div className="pricing-details">{getPricing()}</div>
          <div className="terms-group">
            <input
              type="checkbox"
              id="terms"
              checked={isTermsAccepted}
              onChange={handleCheckboxClick}
              required
            />
            <label htmlFor="terms" className="terms-label">
              Terms of Service
            </label>
          </div>
          <button type="submit" className="setup-button complete-button" disabled={loading}>
            {loading ? <TailSpin color="#fff" height={24} width={24} /> : 'Checkout'}
          </button>
          <TermsOfServiceModal
            isOpen={isModalOpen}
            onAccept={handleModalAccept}
            onClose={handleModalClose}
          />
        </form>
      </div>
    </div>
  );
};

export default MasterAccountSetup;
